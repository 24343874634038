import React from "react"
import styled from "styled-components"
import Moment from "react-moment"

import Layout from "../layout/layout"
import Container from "../Container/index"
import Footer from "../components/Footer"
import Navigation from "../components/Navigation"
import { CategoryBox } from "../components/CategoryBox"
import {
  PostAuthor,
  AuthorIcon,
  AuthorName,
  PostDate,
} from "../components/GalleryItem/index"

import DummyIcon from "../../content/assets/dummyIcon.png"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Content = styled.div`
  padding: 50px 0;
  flex: 1;
  width: 100%;
`

const Title = styled.h1`
  line-height: 1.3;
  margin: 25px 0;
  font-size: 2.5rem;
  @media (min-width: 768px) {
    font-size: 3rem;
  }
  @media (min-width: 1000px) {
    font-size: 3.5rem;
  }
  @media (min-width: 1200px) {
    font-size: 4rem;
  }
`
const Paragraph = styled.p`
  font-size: 1.4rem;
  color: #111;
  margin: 15px 0;
  font-weight: 300;

  @media (min-width: 1200px) {
    font-size: 1.6rem;
  }
`

const Heading = styled.h2`
  line-height: 1.3;
  margin: 25px 0 15px;
  font-size: 2rem;
  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
  @media (min-width: 1000px) {
    font-size: 3rem;
  }
`

const Gallery = styled.div`
  margin: 50px 0;
  display: grid;
  grid-gap: 50px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 500px));
`

const PictureWrapper = styled.div`
  ${({ bg }) => (bg ? `background:url('${bg}');` : null)}
  border-radius:15px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 400px;
  @media (min-width: 1200px) {
    height: 450px;
  }
`

const SubTitle = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(208, 213, 213, 0.5);
  margin-bottom: 30px;
`

const Date = styled(PostDate)`
  font-size: 1.6rem;
  margin-left: 100px;
  color: #818383;
`

const Name = styled(AuthorName)`
  font-size: 1.6rem;
  color: #818383;
`

const BlogPost = ({ pageContext: { data } }) => {
  return (
    <Layout>
      <Navigation />
      <Container>
        <Wrapper>
          <Content>
            {data?.kategoria && <CategoryBox>{data.kategoria}</CategoryBox>}
            {data?.title && <Title>{data.title}</Title>}
            <SubTitle>
              <PostAuthor>
                <AuthorIcon>
                  <img src={DummyIcon} alt="Author Icon" />
                </AuthorIcon>
                <Name>Grzegorz</Name>
              </PostAuthor>
              <Date>
                <Moment format="DD MMM YYYY">{data?.data && data.data}</Moment>
              </Date>
            </SubTitle>
            {data?.zdjecie_glowne && (
              <PictureWrapper bg={data.zdjecie_glowne.url}></PictureWrapper>
            )}
            {data?.naglowek1 && <Heading>{data.naglowek1}</Heading>}
            {data?.opis1 && <Paragraph>{data.opis1}</Paragraph>}
            {data?.edytor1 && (
              <div
                dangerouslySetInnerHTML={{ __html: data.edytor1.html }}
              ></div>
            )}
            {data?.zdjecia1?.length ? (
              <Gallery>
                {data.zdjecia1.map(zdjecie => {
                  return <PictureWrapper bg={zdjecie.url}></PictureWrapper>
                })}
              </Gallery>
            ) : null}
            {data?.naglowek2 && <Heading>{data.naglowek2}</Heading>}
            {data?.opis2 && <Paragraph>{data.opis2}</Paragraph>}
            {data?.edytor2 && (
              <div
                dangerouslySetInnerHTML={{ __html: data.edytor2.html }}
              ></div>
            )}
            {data?.zdjecia2?.length ? (
              <Gallery>
                {data.zdjecia2.map(zdjecie => {
                  return <PictureWrapper bg={zdjecie.url}></PictureWrapper>
                })}
              </Gallery>
            ) : null}
            {data?.naglowek3 && <Heading>{data.naglowek3}</Heading>}
            {data?.opis3 && <Paragraph>{data.opis3}</Paragraph>}
            {data?.edytor3 && (
              <div
                dangerouslySetInnerHTML={{ __html: data.edytor3.html }}
              ></div>
            )}
            {data?.zdjecia3?.length ? (
              <Gallery>
                {data.zdjecia3.map(zdjecie => {
                  return <PictureWrapper bg={zdjecie.url}></PictureWrapper>
                })}
              </Gallery>
            ) : null}
            {data?.naglowek4 && <Heading>{data.naglowek4}</Heading>}
            {data?.opis4 && <Paragraph>{data.opis4}</Paragraph>}
            {data?.edytor4 && (
              <div
                dangerouslySetInnerHTML={{ __html: data.edytor4.html }}
              ></div>
            )}
            {data?.zdjecia4?.length ? (
              <Gallery>
                {data.zdjecia4.map(zdjecie => {
                  return <PictureWrapper bg={zdjecie.url}></PictureWrapper>
                })}
              </Gallery>
            ) : null}
            {data?.naglowek5 && <Heading>{data.naglowek5}</Heading>}
            {data?.opis5 && <Paragraph>{data.opis5}</Paragraph>}
            {data?.edytor5 && (
              <div
                dangerouslySetInnerHTML={{ __html: data.edytor5.html }}
              ></div>
            )}
            {data?.zdjecia5?.length ? (
              <Gallery>
                {data.zdjecia5.map(zdjecie => {
                  return <PictureWrapper bg={zdjecie.url}></PictureWrapper>
                })}
              </Gallery>
            ) : null}

            {data?.naglowek6 && <Heading>{data.naglowek6}</Heading>}
            {data?.opis6 && <Paragraph>{data.opis6}</Paragraph>}
            {data?.edytor6 && (
              <div
                dangerouslySetInnerHTML={{ __html: data.edytor6.html }}
              ></div>
            )}
            {data?.zdjecia6?.length ? (
              <Gallery>
                {data.zdjecia6.map(zdjecie => {
                  return <PictureWrapper bg={zdjecie.url}></PictureWrapper>
                })}
              </Gallery>
            ) : null}

            {data?.naglowek7 && <Heading>{data.naglowek7}</Heading>}
            {data?.opis7 && <Paragraph>{data.opis7}</Paragraph>}
            {data?.edytor7 && (
              <div
                dangerouslySetInnerHTML={{ __html: data.edytor7.html }}
              ></div>
            )}
            {data?.zdjecia7?.length ? (
              <Gallery>
                {data.zdjecia7.map(zdjecie => {
                  return <PictureWrapper bg={zdjecie.url}></PictureWrapper>
                })}
              </Gallery>
            ) : null}

            {data?.naglowek8 && <Heading>{data.naglowek8}</Heading>}
            {data?.opis8 && <Paragraph>{data.opis8}</Paragraph>}
            {data?.edytor8 && (
              <div
                dangerouslySetInnerHTML={{ __html: data.edytor8.html }}
              ></div>
            )}
            {data?.zdjecia8?.length ? (
              <Gallery>
                {data.zdjecia8.map(zdjecie => {
                  return <PictureWrapper bg={zdjecie.url}></PictureWrapper>
                })}
              </Gallery>
            ) : null}

            {data?.naglowek9 && <Heading>{data.naglowek9}</Heading>}
            {data?.opis9 && <Paragraph>{data.opis9}</Paragraph>}
            {data?.edytor9 && (
              <div
                dangerouslySetInnerHTML={{ __html: data.edytor9.html }}
              ></div>
            )}
            {data?.zdjecia9?.length ? (
              <Gallery>
                {data.zdjecia9.map(zdjecie => {
                  return <PictureWrapper bg={zdjecie.url}></PictureWrapper>
                })}
              </Gallery>
            ) : null}

            {data?.naglowek10 && <Heading>{data.naglowek10}</Heading>}
            {data?.opis10 && <Paragraph>{data.opis10}</Paragraph>}
            {data?.edytor10 && (
              <div
                dangerouslySetInnerHTML={{ __html: data.edytor10.html }}
              ></div>
            )}
            {data?.zdjecia10?.length ? (
              <Gallery>
                {data.zdjecia10.map(zdjecie => {
                  return <PictureWrapper bg={zdjecie.url}></PictureWrapper>
                })}
              </Gallery>
            ) : null}

            {data?.naglowek11 && <Heading>{data.naglowek11}</Heading>}
            {data?.opis11 && <Paragraph>{data.opis11}</Paragraph>}
            {data?.edytor11 && (
              <div
                dangerouslySetInnerHTML={{ __html: data.edytor11.html }}
              ></div>
            )}
            {data?.zdjecia11?.length ? (
              <Gallery>
                {data.zdjecia11.map(zdjecie => {
                  return <PictureWrapper bg={zdjecie.url}></PictureWrapper>
                })}
              </Gallery>
            ) : null}

            {data?.naglowek12 && <Heading>{data.naglowek12}</Heading>}
            {data?.opis12 && <Paragraph>{data.opis12}</Paragraph>}
            {data?.edytor12 && (
              <div
                dangerouslySetInnerHTML={{ __html: data.edytor12.html }}
              ></div>
            )}
            {data?.zdjecia12?.length ? (
              <Gallery>
                {data.zdjecia12.map(zdjecie => {
                  return <PictureWrapper bg={zdjecie.url}></PictureWrapper>
                })}
              </Gallery>
            ) : null}

            {data?.naglowek13 && <Heading>{data.naglowek13}</Heading>}
            {data?.opis13 && <Paragraph>{data.opis13}</Paragraph>}
            {data?.edytor13 && (
              <div
                dangerouslySetInnerHTML={{ __html: data.edytor13.html }}
              ></div>
            )}
            {data?.zdjecia13?.length ? (
              <Gallery>
                {data.zdjecia13.map(zdjecie => {
                  return <PictureWrapper bg={zdjecie.url}></PictureWrapper>
                })}
              </Gallery>
            ) : null}

            {data?.naglowek14 && <Heading>{data.naglowek14}</Heading>}
            {data?.opis14 && <Paragraph>{data.opis14}</Paragraph>}
            {data?.edytor14 && (
              <div
                dangerouslySetInnerHTML={{ __html: data.edytor14.html }}
              ></div>
            )}
            {data?.zdjecia14?.length ? (
              <Gallery>
                {data.zdjecia14.map(zdjecie => {
                  return <PictureWrapper bg={zdjecie.url}></PictureWrapper>
                })}
              </Gallery>
            ) : null}

            {data?.naglowek15 && <Heading>{data.naglowek15}</Heading>}
            {data?.opis15 && <Paragraph>{data.opis15}</Paragraph>}
            {data?.edytor15 && (
              <div
                dangerouslySetInnerHTML={{ __html: data.edytor15.html }}
              ></div>
            )}
            {data?.zdjecia15?.length ? (
              <Gallery>
                {data.zdjecia15.map(zdjecie => {
                  return <PictureWrapper bg={zdjecie.url}></PictureWrapper>
                })}
              </Gallery>
            ) : null}
          </Content>
        </Wrapper>
      </Container>
      <Footer />
    </Layout>
  )
}

export default BlogPost
